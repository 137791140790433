footer {
    padding-top: 1rem;
}

@media (min-width: 992px) {
    footer {
          background-image: url("/static/dbase/img/footer.png");
          background-size: 300px;
          background-repeat: no-repeat;
          background-position: bottom center;
          padding-top: 200px;
    }
}

body {
    display: flex;
    height: 100%;
    flex-direction: column;
}

main {
    flex-shrink: 0;
}
