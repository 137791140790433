nav.navbar li > a svg ~ span {
    margin-left: 0.2em;
}

nav.navbar li.active {
    background: #ddd;
}

.input-group .tt-input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group span.twitter-typeahead {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.badge-mb {
    color: #ffc107;
    background-color: #212529;
}
