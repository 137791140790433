$color_1: #495057;
$color_transparent: transparent;
$color_3: #777;

.row > .form-label-group > label {
    /* Fix the floating label if it is in a column */
    margin-left: 15px;
    margin-right: 15px;
    padding-left: inherit;
    padding-right: inherit;
}

.floating-label-size {
    height: 3.125rem;
    padding: 0.75rem;
}

.form-label-group {
	position: relative;
	margin-bottom: 1rem;
	>input, >select, >.btn {
		height: 3.125rem;
		padding: .75rem;
	}
    >.toggle {
        margin-top: 0.5rem;
    }
	>label {
		height: 3.125rem;
		padding: .75rem;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		width: calc(100% - 1.2rem);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
		margin-bottom: 0;
		line-height: 1.5;
		color: $color_1;
		pointer-events: none;
		cursor: text;
		border: 1px solid transparent;
		border-radius: .25rem;
		transition: all .1s ease-in-out;
	}
    .toggle {
        margin-top: 0.72rem;

        &~label {
            margin-top: -0.7rem;
            margin-left: 0;
        }
    }
	input, select, .toggle {
		&::-webkit-input-placeholder {
			color: $color_transparent;
		}
		&:-ms-input-placeholder {
			color: $color_transparent;
		}
		&::-ms-input-placeholder {
			color: $color_transparent;
		}
		&::-moz-placeholder {
			color: $color_transparent;
		}
		&::placeholder {
			color: $color_transparent;
		}
		&:not(:placeholder-shown) {
            padding-top: 1.25rem;
			padding-bottom: .25rem;
			&~label {
				padding-top: .25rem;
				padding-bottom: .25rem;
				font-size: 12px;
				color: $color_3;
			}

            &[type=button], &[type=submit], &[type=reset] {
                padding-top: 0.75rem;
			    padding-bottom: 0.75rem;
            }
		}
	}
}


/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {
  .form-label-group > label {
      display: none;
  }
  .form-label-group .toggle, .form-label-group select {
      &~label {
          display: block;
          font-size: 12px;
          margin-top: -1.2rem;
          color: #777777;
      }
  }
  .form-label-group select {
        &~label {
            margin-top: -0.8rem;
        }
    }
  .form-label-group input::-ms-input-placeholder {
    color: #777;
  }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .form-label-group > label {
    display: none;
  }
  .form-label-group input:-ms-input-placeholder {
    color: #777;
  }
}
